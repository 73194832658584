import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import Homepage3 from '../HomePage3'
import NewHome from '../Home'
import AboutPage from '../AboutPage'
import PracticePage from '../PracticePage'
import CaseStadies from '../CaseStadies'
import Attorneys from '../Attorneys'
import CaseStadiesDetails from '../Casedetails'
import PracticeDetails from '../PracticeDetails'
import Attorneysdetails from '../Attorneysdetails'
import ContactPage from '../ContactPage'
import FAQPage from '../FAQPage'
import BlogPage from '../BlogPage'
import BlogPageSidebar from '../BlogPageSidebar'
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogSingleSidebar from '../BlogSingleSidebar'
import BlogDetailsFullwidth from '../BlogDetailsFullwidth'
import Welcome from '../Welcome'
import Partenaire from '../Partenaire'
import Speakers from '../Speakers'
import Workshop from '../WorkSpaces'
import TripAbout from '../TripAbout'
import Commitees from '../Commities'
import Conferance from '../Commities/Conferance'
import Callofpapers from '../Callofpapers'
import FieldTrip from '../Fieldtrips'
import ProgramSocial from '../SocialProgram'
import Authoguidelines from '../Authorguidelines'
import Registration from '../Register'
import RegistrationFroms from '../Register/resgistreforms'

import Accomodation from '../Accomodation'
import AccesPlan from '../AccessPlan'
import SpecialIsuse from '../SpecialIssues'
import Contact from '../Contact'

const AllRoute = () => {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path='/' component={NewHome} />
          <Route path='/welcome' component={Welcome} />
          <Route path='/contact' component={Contact} />
          <Route path='/partenaire' component={Partenaire} />
          <Route path='/important-dates' component={Partenaire} />
          <Route path='/speakers' component={Speakers} />
          <Route path='/workspaces' component={Workshop} />
          <Route path='/trip/tunisia' component={TripAbout} />
          <Route path='/committees' component={Commitees} />
          <Route path='/sessions' component={Conferance} />
          <Route path='/download' component={Callofpapers} />
          <Route path='/filed/trips' component={FieldTrip} />
          <Route path='/social/program' component={ProgramSocial} />
          <Route path='/author/guidelines' component={Authoguidelines} />
          <Route path='/accomodation' component={Accomodation} />
          <Route path='/access/plan' component={AccesPlan} />
          <Route path='/special/issue' component={SpecialIsuse} />
          <Route path='/registration' component={Registration} />
          <Route path='/forms/registration/form' component={RegistrationFroms} />
          <Route path='/program/details' component={Attorneysdetails} />
          
          
          




          <Route path='/JU' component={Homepage} />
          <Route path='/home' component={Homepage} />
          <Route path='/home2' component={Homepage2} />
          <Route path='/home3' component={Homepage3} />
          <Route path='/about' component={AboutPage} />
          <Route path='/practice' component={PracticePage} />
          <Route path='/case-stadies' component={CaseStadies} />
          <Route path='/Attorneys' component={Attorneys} />
          <Route path='/case-stadies-details' component={CaseStadiesDetails} />
          <Route path='/practice-details' component={PracticeDetails} />
          <Route path='/Attorneys-single' component={Attorneysdetails} />
          <Route path='/contact' component={ContactPage} />
          <Route path='/faq' component={FAQPage} />
          <Route path='/blog' component={BlogPage} />
          <Route path='/blog-list' component={BlogPageSidebar} />
          <Route path='/blog-fullwidth' component={BlogPageFullwidth} />
          <Route path='/blog-single' component={BlogSingleSidebar} />
          <Route path='/blog-single-fullwidth' component={BlogDetailsFullwidth} />

        </Switch>
      </Router>

    </div>
  );
}

export default AllRoute;
