import React from  'react';

// components
import Navbar from '../../components/Navbar'

import FooterSection from '../../components/Footer'
import WlecomeImages from '../../components/CallforPapers/welcomeImages'
import Wlecome from '../../components/CallforPapers/index'


const WelcomePage = () => {
    return(
       <div className="practicepage">
           <Navbar/>
           <WlecomeImages bdtitle={'Welcome'} bdsub={'Welcome'}/>
           <Wlecome/>
           <FooterSection/>
       </div>
    )
}

export default WelcomePage;