import React from 'react';

import home_welcom from '../../images/HomeKeys/home_welcom.png'
import v16 from '../../images/HomeKeys/v16.png'
import V14 from '../../images/HomeKeys/v14.png'
import V18 from '../../images/HomeKeys/v18.png'
import V17 from '../../images/HomeKeys/v17.png'
import V15 from '../../images/HomeKeys/v15.png'
import luxury from '../../images/HomeKeys/luxury.png'
import V12 from '../../images/HomeKeys/v12.png'
import V10 from '../../images/HomeKeys/v10.png'
import programe from '../../images/HomeKeys/programe.png'
import fieldtrip from '../../images/HomeKeys/fieldtrip.png'
import courses from '../../images/HomeKeys/courses.png'


import './style.css'

function Features() {
    const list = [
        {
            image: home_welcom,
            title: 'welcome',
            link: ""
        },
        {
            image: v16,
            title: 'committess',
            link: ""
        },
        {
            image: V14,
            title: 'keynote',
            link: ""
        },
        {
            image: V15,
            title: 'Importants Dates',
            link: ""
        },
        {
            image: programe,
            title: 'Program',
            link: ""
        },
        {
            image: V12,
            title: 'Author Guidelines',
            link: ""
        },

        {
            image: V10,
            title: 'Publications',
            link: ""
        },

        {
            image: luxury,
            title: 'Accomodation',
            link: ""
        },
        {
            image: fieldtrip,
            title: 'Field trips',
            link: ""
        },
        {
            image: courses,
            title: 'Courses & workshps',
            link: ""
        },
        {
            image: V17,
            title: 'Social Program',
            link: ""
        },
        {
            image: V18,
            title: 'Registration',
            link: ""
        },




    ]
    return (
        <div className="features-area ">
            <div className="container">
                <div className="row">
                    {list.map((e)=>(
                        <div className="col-lg-4 col-md-6 col-sm-12 col-p">
                        <div className="features-item-2">
                            <div className="features-icon">
                                <i className="fi"><img src={e.image} alt={e.title} /></i>
                            </div>
                            <div className="features-content">
                                <h3>{e.title}</h3>
                            </div>
                        </div>
                    </div>
                  
                    ))}
                </div>
            </div>
        </div>
    )

}

export default Features;
