import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/Specialissues/index'

import Specialissues from '../../components/Specialissues/guidelines'
import FooterSection from '../../components/Footer'


const FAQPage = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={'Organizing Committee  '} bdsub={' Organizing Committee   '}/>
           <Specialissues/>

           <FooterSection/>
       </div>
    )
}

export default FAQPage;