import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './style.css'


class SimpleSlider extends Component {
    render() {
        var settings = {
            dots: true,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            fade: true
        };
        return (
            <section className="hero hero-slider-wrapper hero-style-1">
                <div className="hero-slider">
                    <Slider {...settings}>
                        <div className="slide1 slide">
                            <div className="container">
                                <div className="row">
                                    <div className="col col-lg-8 slide-caption">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide2 slide">
                            <div className="btns">
                                <div className="btn-style"><Link to="/contact">Contact us now</Link></div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col col-lg-8 slide-caption">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide3 slide">
                            <div className="container">
                                <div className="row">
                                    <div className="col col-lg-8 slide-caption">
                                        <div className="btns">
                                            <div className="btn-style"><Link to="/contact">Contact us now</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        );
    }
}

export default SimpleSlider;