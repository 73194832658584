import React from  'react';

// components
import Navbar from '../../components/Navbar'

import FooterSection from '../../components/Footer'
import WlecomeImages from '../../components/Accomodation/welcomeImages'
import Accomodation from '../../components/Accomodation/index'


const WelcomePage = () => {
    return(
       <div className="practicepage">
           <Navbar/>
           <WlecomeImages bdtitle={'Conference Hotel'} bdsub={'Conference Hotel'}/>
           <Accomodation/>
           <FooterSection/>
       </div>
    )
}

export default WelcomePage;