import React from 'react';
import VideoModal1 from './Video.V1'
import VideoModal2 from './Video.V2'
// import { Link } from 'react-router-dom'
import abimg1 from '../../images/about/medgu2022.jpg'
// import abimg2 from '../../images/about/img-1.png'
import Mediterranean from '../../images/about/Mediterranean.png'
import welcomet1 from '../../images/welcomepage/carte.jpg'
import welcomet2 from '../../images/welcomepage/Photo 3.jpg'

import './style.css'

const AboutSection = () => {

    return (
        <div className="about-style-2">

            <div className="container">
            <div  className="col-l2">
                    <div style={{padding:"-50px"}} className="section-title-1 text-center">
                    <h2>Contribution of Geodesy to geodynamics and seismic hazards assessment</h2>

                    </div>
                </div>
            
                <div className="row">
                    <div className="col-lg-6 col-sm-12 col-m">

                       
                        <div className="signature ">
                                <img src={welcomet1} alt="" />
                            </div>
                            {/* <div className="signature about-img">
                                <img src={welcomet2} alt="" />
                            </div> */}

                       
                        <div className="about-content-1">
                            <div className="section-title">
                            {/* <li>
                                <p>Mediterranean Geoscience Reviews (MEGR, Springer) will host a special issue on the best papers from WEGENER-20. MEGR will be indexed this year in Web of Science and Scopus. </p>
                            </li>
                            <li>
                                <p>More special issues are planned in other journals. All presenting authors will be invited to submit to these special issues. See the full list of our partner journals on Publications.</p>
                            </li> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="about-content-1">
                            <div className="section-title">
                                {/* <h2>WEGENER-20 WILL GO AHEAD IN-PERSON & ONLINE THIS OCTOBER IN Sousse.
                                    WE WELCOME PARTICIPANTS FROM ALL OVER THE WORLD!</h2> */}
                            </div>
                            <li>
                                <p>
                                The National Office of Mines (ONM, Geological Survey of Tunisia) welcome the 20th WEGENER Assembly from 24 to 26 October 2023 in Sousse, Tunisia.</p>
                            </li>
                            <li>
                                <p>
                                This assembly aims to gather geo-scientists and share recent progress in space geodesy (GPS and InSAR) and knowledge in seismo-geodesy, deformation of volcanic fields. A special attention will be devoted to the implications for the seismic hazard and risk reduction to foster discussion and new proposals for joint research projects.
                                </p>
                            </li>
                            {/* <li>
                                <p>If you have been selected (i.e. short abstract accepted), we will contact you on 1 August 2023 to check whether you want to attend the conference in person and present your work to the audience directly or if you would prefer to make your presentation online.</p>
                            </li>
                            <li>
                                <p>You will have until 31 August 2023 to decide on the type of presentation (in-person or online).</p>
                            </li>
                            <li>
                                <p>Registration deadline: 31 August 2023</p>
                            </li>
                            <li>
                                <p>Deadline to submit (optional, not mandatory) an extended abstract (max. 5 pages) for publication in the proceedings by Springer (indexed in Scopus / SCImago): 15 September 2023.</p>
                            </li>
                            <li>
                                <p>See the meeting deadlines at the Important Dates.</p>
                            </li>
                            <li>
                                <p>Before doing any trip planning, be sure to check the Travel & Entry Restrictions to Tunisia.</p>
                            </li>
                            <li>
                                <p>Thank you for your great interest in joining WEGENER-20 in October 2023!</p>
                            </li> */}
                            {/* <div className="btns">
                                <div className="btn-style"><Link to="/">More About Us..</Link></div>
                            </div> */}
                            {/* <img src={abimg1} alt="" /> */}
                            <div className="signature ">
                                <img src={welcomet2} alt="" />
                       
                            {/* <VideoModal1 videoId={"'zmnI8swJruY'"} /> */}
                        </div>
                        {/* <div className="about-img">
                            <img src={abimg1} alt="" />
                            <VideoModal2 videoId={"QZNiVF-HQtw&t"} />
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AboutSection;
