import React, { Component } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'

import PerfectScrollbar from 'react-perfect-scrollbar'

import './style.css';

import 'react-perfect-scrollbar/dist/css/styles.css';
const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/',
    },
    {
        id: 2,
        title: 'About Us',
        link: '/about',
        submenu: [
            // {
            //     id: 11,
            //     title: 'welcome',
            //     link: '/welcome'
            // },
            {
                id: 12,
                title: 'Wegener-20',
                link: '/trip/tunisia'
            },
            {
                id: 13,
                title: ' Organizing Committee',
                link: '/committees'
            },
            {
                id: 788221,
                title: 'Scientific Committee ',
                link: '/special/issue',
            },
            {
                id: 14,
                title: 'About Wegener',
                link: '/sessions'
            }, {
                id: 15,
                title: 'Organizers & Sponsors',
                link: '/partenaire'
            }, {
                id: 16,
                title: 'Field Trips',
                link: '/filed/trips'
            }, {
                id: 17,
                title: 'Workshops & Trainings',
                link: '/workspaces'
            }, {
                id: 18,
                title: 'Sessions',
                link: '/speakers'
            },
            , {
                id: 1909,
                title: 'Venue',
                link: '/access/plan'
            },
            
        ]
    },
    {
        id: 3,
        title: 'Program',
        link: '/program/details'

    },
    {
        id: 1990,
        title: 'Submission',
        link: '/author/guidelines'

    },
    {
        id: 1923,
        title: 'registration',
        submenu: [
            {
                id: 5190,
                title: 'registration fees',
                link: '/registration'
            },
            {
                id: 52132,
                title: 'registration form',
                link: '/forms/registration/form'
            },

        ]

    },

    {
        id: 5,
        title: 'registration form',
        link: '/forms/registration/form'


    },


]


class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {

        // alert("hhe")
        // alert("jj")
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        return (
            <div>
                <PerfectScrollbar >
                    <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>

                        <ul className="responsivemenu">

                            {menus.map(item => {
                                return (
                                    <li key={item.id}>
                                        {item.submenu ? <p onClick={this.setIsOpen(item.id)}>
                                            {item.title}
                                            {item.submenu ? <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}
                                        </p> : <Link to={item.link}>{item.title}</Link>}
                                        {item.submenu ?
                                            <Collapse isOpen={item.id === isOpen}>
                                                <Card>
                                                    <CardBody>
                                                        <ul>
                                                            {item.submenu.map(submenu => (
                                                                <li key={submenu.id}><Link className="active" to={submenu.link}>{submenu.title}</Link></li>
                                                            ))}
                                                        </ul>
                                                    </CardBody>
                                                </Card>
                                            </Collapse>
                                            : ''}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </PerfectScrollbar>
                <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>
            </div>
        )
    }
}

export default MobileMenu;