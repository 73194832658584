import React from 'react';

import { Link } from 'react-router-dom'
import welcomet123 from '../../images/newimages/logos.jpg'

import Logocnes from '../../images/newimages/Logocnes.jpg'
import './stylewelcome.css'

const Breadcumb = (props) => {

    return (
        <div className="breadcumb-areaahome">
            <div style={{
                alignItems: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} className=" ">
                <img style={{ height: "100px", width: "85%", alignItems: 'center' }} src={welcomet123} alt="" />
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} className=" ">
                <img style={{ height: "100px",  alignItems: 'center' }} src={Logocnes} alt="" />
            </div>
        </div>
    )

}

export default Breadcumb;
