import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/Welcome/welcomeImages'

import CaseDetails from '../../components/SocialProgram'
import FooterSection from '../../components/Footer'


const ProgramSocial = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={'Social Program'} bdsub={'Social Program'}/>
           <CaseDetails/>
           <FooterSection/>
       </div>
    )
}

export default ProgramSocial;