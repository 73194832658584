import React, { Component } from 'react';
import './style.css'

class AttorneysDetails extends Component {

    render() {

        return (
            <div className="Attorneys-content-section section-padding">
                <div className="container">


                    <div className="organigation-text">
                        <h2>Field Trips</h2>
                        <p> More details will be made available soon. </p>

                        {/* <h5>Field Trip 1: Archeoseismology and surface effects of historical earthquakes in Kairouan area (Central Tunisia)</h5> */}
                        <br></br>
                        <h5>Active faults near Sfax city and catastrophic deposits related to the paleotsunami (East Tunisia coastline)
                        </h5>


                    </div>
                </div>
            </div>
        );
    }
}

export default AttorneysDetails;