import React from 'react'
import './styleguide.css'
import Accordion from 'react-bootstrap/Accordion'
import { Card, Button } from 'react-bootstrap'
import cd_1 from '../../images/about/ASTI-Series.jpg'
import PublicationPlanSummary from '../../images/about/PublicationPlanSummary.jpg'
import image1 from '../../images/publication/t1.png'
import image2 from '../../images/publication/t2.png'
import image3 from '../../images/publication/t3.png'
import image4 from '../../images/publication/t4.png'
import image5 from '../../images/publication/t5.png'
import image6 from '../../images/publication/t6.png'
import image7 from '../../images/publication/t7.png'
import image8 from '../../images/publication/t8.png'
import image9 from '../../images/publication/t9.png'
import image10 from '../../images/publication/t10.png'
import imageTP1 from '../../images/publication/p1-cajg-2.png'
import imageTP2 from '../../images/publication/p2-cajg-2.png'
import imageTP3 from '../../images/publication/p3-cajg-2.png'
import imageTP4 from '../../images/publication/p4-cajg-2.png'

const FaqSection = () => {
    return (
        <section className="faq-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* <div className="section-title">
                            <span> Publications  </span>
                            <h2> Publications  </h2>
                        </div> */}
                    </div>
                </div>
                <div className="col-l2">
                    <div className="section-title-1 text-center">
                        <span>Organizing Committee</span>
                        <h2>Scientific Committee </h2>
                    </div>
                </div>

                <div className="organigation-text">

                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Nejib Bahrouni (Active Tectonics) Office National des Mines, Tunisia</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Mustapha Meghraoui (Active Tectonics) EOST - ITE Strasbourg, France</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Jean-Mathieu Nocquet (GPS) GeoAzur, IRD, Nice , France</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Abdelali Fadil (GPS) Faculté des Sciences, Semlalia, Marrakech, Morocco</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Ahmed Ksentini (Seismic Hazard) Sfax University, Tunisia</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Andy Hooper (InSAR) Leeds University, United Kingdom</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Baptiste Rousset (InSAR + GPS) EOST - ITE Strasbourg, France</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Cecile Lasserre (InSAR, Lyon, formater, flatsim) Laboratoire de Géologie de Lyon, France</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Damien Delvaux (Active Tectonics) Musée Royal d’Afrique Centrale, Belgium</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Elias Lewi (InSAR + GPS) Addis Ababa University, Ethiopia</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Fawzi Doumaz (Geomatics) Istituto Nazional di Geofisica, Rome</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        François Roure (Continental Tectonics) IFP, France</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Haluk Ozener (GPS) Kandilli Observatory, Turkey</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Hamoud Beldjoudi (Seismotectonics) Centre de Recherche en Geophysique, Algeria</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Jeffrey Freymueller (Michigan State University, USA)</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Klaus-G Hinzen (Seismology) Cologne University, Germany</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Mahdi Motagh (GFZ Potsdam, Germany)</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Mariane Metois (GPS, Lyon) Laboratoire de Géologie de Lyon, France</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Mohamed Saleh (InSAR + GPS) NRIAG, Cairo, Egypt</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Nicola d’Agostino (GPS) Istituto Nazional di Geofisica, Rome, Italy</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Pablo Gonzalez (InSAR) Life and Earth Sciences (IPNA-CSIC), Tenerife, Spain</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Rui Manuel Fernandes (GPS) Beira Univ., Portugal</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Salem Kahlouche (ASAL-CTS Arzew, Algeria)</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Semih Ergintav (GPS) Kandilli Obervatory, Bogaziçi University, Turkey</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Takuya Nishimura (GPS) Kyoto Univ., Japan</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Tim Wright (InSAR) Leeds University, UK</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                        Ziyadin Cakir (InSAR + GPS) Istanbul Technical University, Turkey</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i> Zoheir Altamimi (Geodesy, GPS) Institut Géographique National, Paris, France </span>



                </div>
                {/* <div className="row">
                    <div className="col-lg-12">
                        <Accordion className="choose-info" defaultActiveKey="">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Conference Proceedings
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>

                                        <div className="organigation-text">
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   All authors of accepted abstracts will be invited to submit (optional) an extended version (short conference paper of a max. of 5 pages including figures and references) for evaluation. All accepted short papers after the pre-conference peer-review process will be published as chapters in the conference proceedings by Springer. The registered author(s) of each accepted paper will receive a "Springer Certificate of Acceptance" certifying that the paper has been accepted for publication in the conference proceedings.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   The proceedings of the WEGENER-20 will be published online on 01 May 2024 by Springer. All authors will receive a soft copy of the proceedings shortly after online publication.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   The proceedings of the WEGENER-20 will be made available in the SpringerLink Digital Library which is directly linked to Research Gate and will be published in Springer ASTI series which is indexed in Scopus (CiteScore = 0,6 as of February 2023):</span>
                                            <div className="practice-section-img practice-section-img-2">
                                                <img style={{ width: "100%" }} src={cd_1} alt="" />
                                            </div>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>    Note that authors of accepted papers in the conference proceedings (after the pre-conference peer review process) and who do not plan to attend the WEGENER-20 should register before the deadline of 31 August 2023 (see Important Dates and Registration). If you are not sure whether you or one of your co-authors will be able to attend the conference, you should bear in mind that there is a fee to be paid before this deadline to cover the editorial handling and evaluation of your paper before publication in the conference proceedings. The accepted paper will not be included in the conference proceedings without paying the fee. If you pay the fee and decide to attend the conference afterward, the paid amount will be deduced from your total registration fee.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>    The proceedings of WEGENER-20 will consist of 8 sections, each edited by the Track Chairs (see Conference Sessions) and other Editors who will be selected from the list of the most supportive reviewers.</span>

                                        </div>
                                    </Card.Body>





                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        Related Conference Proceedings                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="organigation-text">

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>        The proceedings of the 1st CAJG was published in 10 volumes in November 2018. It gives a general and brief overview on current research focusing on emerging geosciences topics. It contains over 700 carefully refereed short contributions to the conference:</span>
                                            <h1> list imagee</h1>
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={image1} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={image2} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={image3} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={image4} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={image5} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={image6} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={image7} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={image8} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={image9} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={image10} alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  The proceedings (4 volumes) of the 2nd CAJG appeared online as part of the ASTI series on SpringerLink. It summarizes new research studies focusing on emerging geosciences topics and it contains over 400 carefully refereed short contributions to the conference: </span>
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={imageTP1} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={imageTP2} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={imageTP3} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="practice-section-img practice-section-img-2">
                                                        <img style={{ width: "100%" }} src={imageTP4} alt="" />
                                                    </div>
                                                </div>
                                              
                                            </div>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  The proceedings (4 volumes) of the 3rd CAJG is now in final preparation before online publication.</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> The proceedings of the 1st MedGU and 2nd MedGU are now in final preparation before online publication in Summer 2023.</span>

                                        </div>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        Oral Communications                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>


                                        <p>There are three types of oral communications:</p>


                                        <div className="organigation-text">

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  In-person PowerPoint (PPT) presentation of 12 minutes (followed by 3-minutes Q/A).</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  Online (recorded) PPT presentation of 12 minutes (followed by 3-minutes Q/A).</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  In-person Poster or e-Poster presentation during a session of two hours.</span>




                                        </div>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                        Awards                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>

                                        <div className="organigation-text">


                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> During the conference the best paper selected from each conference track (8 papers in total), as judged by the session conveners, will receive awards (see Conference Sessions).</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>            Decisions are based on the following criteria:</span>
                                            <div style={{ marginLeft: "10%" }} >
                                                <li>Originality and newness of contribution</li>
                                                <li>Appropriate application of methodology</li>
                                                <li>Effective communication of the central ideas of the work</li>
                                                <li>Usefulness of the research in resolving environmental and/or societal problem(s) for more sustainability</li>
                                            </div>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   The 8 winning authors will receive the “WEGENER Award for Outstanding Paper,” a gift voucher for the amount of 300 euros which can be used to purchase books on SpringerLink, and also a bag personalized with the WEGENER logo and an advertising message “Winner of the best conference paper at WEGENER – Istanbul 2023.” All winners will be presented with a commemorative certificate and will be acknowledged during the awards luncheon (Day 3 in the Conference Program).</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   The most active reviewers and editors will also receive WEGENER awards and certificates of appreciation and will be acknowledged during the awards luncheon.</span>
                                        </div>


                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="13">
                                        Special Issues
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="13">
                                    <Card.Body>
                                        <div className="organigation-text">
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>The WEGENER-20 scientific committee has agreed with the Editors-in-Chief of Mediterranean Geoscience Reviews, Earth Systems and Environment, Euro-Mediterranean Journal for Environmental Integration, Journal of Petroleum Exploration and Production Technology, Innovative Infrastructure Solutions, Arabian Journal of Geosciences and Journal of King Saud University – Science to publish high-quality topical collections (special issues) on cross-cutting themes from the earth and environmental sciences.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Authors of the selected papers will be notified on 31 October 2023 to start preparing an extended version of their proceedings papers or accepted (short) abstracts (not extended/published in the proceedings) for submission in one of the special issues.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>All papers will be peer-reviewed by Editors of each journal before final acceptance for publication.</span>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="130">
                                        Publication Plan: Summary                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="130">
                                    <Card.Body>
                                        <div className="organigation-text">
                                            <div className="practice-section-img practice-section-img-2">
                                                <img style={{ width: "100%" }} src={PublicationPlanSummary} alt="" />
                                            </div>

                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                    </div>
                </div> */}
            </div>
        </section>
    )
}
export default FaqSection;