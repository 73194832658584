import React from  'react';

// components
import Navbar from '../../components/Navbar'

import FooterSection from '../../components/Footer'
import WlecomeImages from '../../components/Welcome/welcomeImages'
import Wlecome from '../../components/Welcome/index'


const WelcomePage = () => {
    return(
       <div className="practicepage">
           <Navbar/>
           <WlecomeImages bdtitle={''} bdsub={''}/>
           <Wlecome/>
           <FooterSection/>
       </div>
    )
}

export default WelcomePage;