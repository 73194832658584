import React from 'react';
import { Link } from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'
import logo from '../../images/logo/logowegner.png'
import onmImage from '../../images/orghost/logoin.png'
import './style.css'

const Header = () => {

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <header>
            <div className="header-top-1" >
                <div style={{paddingLeft:"15%"}} className="">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 col-lg-9">
                            <ul className="d-flex account_login-area">
                                {/* <li><i className="fa fa--o" aria-hidden="true"></i>ONM</li> */}
                                <li><i className="fa fa-map-marker"></i>Siège social de l'ONM 24,rue de L'Energie, 2035 La Charguia - Tunis</li>
                                <li><i className="fa fa-phone"></i>(+216) 71 808 042 / 71 808 266 / 93 027 098 </li>
                                <li><i className="fa fa-envelope"></i> wegener.assembly@onm.nat.tn</li>
                          
      {/* Tél. : (+216) 71 808 042 / 71 808 266
      Fax : (+216) 71 808 098
      Email : wegener.assembly@onm.nat.tn
      Adresse : 24 rue de l'Energie, 2035 La Charguia - Tunis BP: 215 – 1080 Tunis Cedex – Tunisie */}

                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            {/* <div className="btn-style"><Link to="/">Free Consultation</Link></div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-style-1">
                <div  >
                    <div className="row">
                        <div className="col-lg-2 col-md-10 col-sm-10 col-8 col-t">
                            <div className="logo">
                                <Link to="/"><img src={logo} alt="log" /></Link>
                            </div>
                        </div>
                        <div className="col-lg-8 d-none d-lg-block col-m">
                            <div className="main-menu">
                                <nav className="nav_mobile_menu">
                                    <ul>
                                        <li className="active"><Link to="/">Home</Link>

                                        </li>
                                        <li><Link>About WEGNER-20</Link>
                                            <ul className="submenu">
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-12 col-m">
                                                        {/* <li><Link to="/welcome">welcome</Link></li> */}
                                                        <li><Link to="/sessions">About Wegener</Link></li>
                                                        <li><Link to="/trip/tunisia">Wegener-20</Link></li>

                                                        <li><Link to="/committees"> Organizing Committee</Link></li>
                                                        <li><Link to="/special/issue">Scientific Committee  </Link></li>
                                                        {/* <li><Link to="/download"> Call for Papers</Link></li> */}
                                                        {/* <li><Link to="/social/program"> Social Program </Link></li> */}
                                                        <li><Link to="/access/plan">Venue</Link></li>


                                                    </div>
                                                    <div className="col-lg-6 col-sm-12 col-m">
                                                        <li><Link to="/partenaire">Organizers & Sponsors</Link></li>
                                                        <li><Link to="/filed/trips"> Field Trips</Link></li>
                                                        {/* <li><Link to="/welcome">important-dates</Link></li> */}
                                                        <li><Link to="/speakers">Sessions</Link></li>
                                                        <li><Link to="/workspaces">Trainings</Link></li>
                                                        {/* <li><Link to="/trip/tunisia">Your Trip to Tunisia & Trainings</Link></li> */}



                                                    </div>
                                                </div>


                                            </ul>
                                        </li>
                                        <li><Link to="/program/details">Program </Link></li>

                                        <li><Link to="/author/guidelines">Submission</Link>

                                        </li>

                                        <li><Link>registration </Link>
                                            <ul className="submenu">
                                                <li><Link to="/registration">registration fees </Link></li>
                                                <li><Link to="/forms/registration/form">registration form</Link></li>

                                            </ul>
                                        </li>
                                        <li><Link to="/forms/registration/form">registration Forms </Link></li>
                                        <li><Link to="/contact">contact </Link></li>

                                        {/* <li><Link to="/author/guidelines">Venue & Accommodation</Link>
                                            <ul className="submenu">
                                                <li><Link to="/accomodation">Conference Hotel </Link></li>

                                            </ul>

                                        </li> */}









                                        {/* <li><Link to="/contact">Contact</Link></li> */}
                                        {/* <li><Link to="/practice">Practices</Link>
                                            <ul className="submenu">
                                                <li><Link to="/practice">practice</Link></li>
                                                <li><Link to="/practice-details">practice details</Link></li>
                                                <li><Link to="/home1">Home One</Link></li>
                                                <li><Link to="/home2">Home Two</Link></li>
                                                <li><Link to="/home3">Home Three</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/">Cases</Link>
                                            <ul className="submenu">
                                                <li><Link to="/case-stadies">Cases</Link></li>
                                                <li><Link to="/case-stadies-details">Case single</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/">Blog</Link>
                                            <ul className="submenu">
                                                <li><Link to="/blog">Blog Grid</Link></li>
                                                <li><Link to="/blog-list">Blog with sidebar</Link></li>
                                                <li><Link to="/blog-fullwidth">Blog full width</Link></li>
                                                <li><Link to="/blog-single">Blog single sidebar</Link></li>
                                                <li><Link to="/blog-single-fullwidth">Blog single fullwidth</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/">Pages</Link>
                                            <ul className="submenu">
                                                <li><Link to="/Attorneys">Attorneys</Link></li>
                                                <li><Link to="/Attorneys-single">Attorney Single</Link></li>
                                                <li><Link to="/faq">FAQ</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/contact">Contact</Link></li> */}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        {/* <div className="col-lg-1 col-md-1 col-sm-1 col-1 search">
                            <ul>
                                <li><Link to="/"><i className="fa fa-search"></i></Link>
                                    <ul>
                                        <li>
                                            <form onSubmit={SubmitHandler}>
                                                <input type="text" placeholder="search here.." />
                                                <button type="btn"><i className="fa fa-search"></i></button>
                                            </form>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div> */}
                         <div className="col-lg-2 col-md-10 col-sm-10 col-8 col-t">
                            <div >
                                <div className='row' >
                                <div className="col-lg-10 ">
                                <Link to="/"><img src={onmImage} alt="log" /></Link>
                                {/* <p>host institution</p> */}
                              
                                </div>
                                {/* <div className="col-lg-2 ">
                                <p>host instituation</p>

                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-1 col-1">
                            <MobileMenu />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )

}

export default Header;
