import React from 'react';
import VideoModal1 from './Video.V1'
import VideoModal2 from './Video.V2'
import { Link } from 'react-router-dom'
// import abimg2 from '../../images/about/img-1.png'
import Mediterranean from '../../images/blog/emcie.jpg'
import abimg1 from '../../images/blog/emcie.png'

import './style.css'

const AboutSection = () => {

    return (
        <div className="about-style-2">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12 col-12">
                        <div className="about-content-1">
                            <div className="section-title">
                                <h2>Wegener-20</h2>
                            </div>
                            {/* <div className="about-img">
                                <img src={abimg1} alt="" />
                                <VideoModal1 videoId={"'zmnI8swJruY'"} />
                            </div> */}
                            {/* <div className="section-title">
                                <h2>What do you need to know to prepare your trip to Tunisia?</h2>
                            </div> */}

                            <li>
                                <p>   The 20th WEGENER Assembly 2023 will be organized by the National Office of Mines (ONM, Geological Survey of Tunisia) from 24 to 27 October 2023 in Sousse, Tunisia.

                                </p>
                            </li>
                            <li>
                                <p> The ONM has shown a substantial interest in the processing of GNSS data and the geodynamic study of Tunisia since 2019.
                                </p>
                            </li>
                            <li>
                                <p>   The 20th Assembly of Wegener will be held in collaboration with the National Institute of Meteorology (INM, in charge of Seismology) and the Office of Topography and Cadastre (OTC, in charge of Geodesy), with support from the International Union of Geodesy and Geophysics (IUGG), the International Association of Geodesy (IAG), the Centre National d'Etudes Spatiales (CNES), The Géoazur laboratory, the International Association of Seismology (IASPEI) and the North African Group for Earthquake and Tsunami studies (NAGET).
                                </p>
                            </li>
                            <li>
                                <p> This assembly aims to gather geo-scientists and share recent progress in space geodesy (GPS and InSAR) and knowledge in seismo-geodesy, deformation of volcanic fields. A special attention will be devoted to the implications for the seismic hazard and risk reduction to foster discussion and new proposals for joint research projects.
                                </p>
                            </li>
                            {/* <li>
                                <p>Visa application.</p>
                            </li>
                            <p>International participants will need a passport valid for at least 90 days following their departure date from Tunisia. Please make sure your passport has blank visa pages.</p>

                            <p>Participants from the following countries do not need a visa for a stay of less than three months:</p>

                            <p>Algeria, Andorra, Angola, Antigua, Argentina, Australia, Austria, Bahrain, Barbados, Belarus, Belgium, Bermuda, Bosnia, Brazil, Brunei, Bulgaria, Burkina Faso, Canada, Cape Verde Islands, Chile, Comoros, Costa Rica, Croatia, Czech Republic, Denmark, Dominique, Equatorial Guinea, Estonia, Fiji Islands, Finland, France, Gabon, Gambia, Germany, Greece, Guinea, Guinea - Bissau, Honduras, Hong Kong, Hungary, Iceland, Ireland, Italy, Ivory Coast, Japan, Jordan, Kazakhstan, Kiribati, Kuwait, Latvia, Libya, Lichtenstein, Lithuania, Luxembourg, Macedonia, Malaysia, Maldives, Mali, Malta, Mauritania, Mauritius, Mexico, Moldova, Monaco, Montenegro, Morocco, Namibia, Netherlands, Netherlands Antilles, New Zealand, Niger, Norway, Oman, Poland, Portugal, Qatar, Romania, Russia, Saint Kitts and Nevis, Saint Lucia, San Marino, Saudi Arabia, Senegal, Serbia, Seychelles , Singapore, Slovakia, Slovenia, Solomon Islands, South Africa, South Korea, Spain, Sweden, Switzerland, Turkey, United Arab Emirates, United Kingdom, United States</p>

                            <p>Participants from countries not mentioned above are advised to contact their nearest Tunisian Embassy to check visa entry requirements. Please check at least three months before travelling. In order to assist with the travel visa application process, we will provide a letter of invitation upon request (see Contact). If you encounter problems in the application process, please feel free to contact us.</p>

                            <p>A visa costs around 30 EUR and is valid for 90 days.</p>

                            <p>Please check the website of the Tunisian Ministry of Foreign Affairs to see if you need an entry visa to Tunisia</p>

                            <li>
                                <p>Useful information.</p>
                            </li>
                            <p>Please take out comprehensive travel and medical insurance before travelling.</p>

                            <p>ATMs are widely available in Tunisia though they don’t always work. Almost all ATMs will accept Visa cards, and many will also accept Maestro cards for cash withdrawals. Credit and debit cards are accepted in some but not all of the larger shops, restaurants and hotels.</p>

                            <p>1 Tunisian Dinar = 1/3 EUR</p>

                            <p>The temperature drops into the realms of warm to mild in Tunisia’s Mediterranean resorts during late October. With an average maximum temperature of 22°C, Tunisia in late October is heading for its cool, wet, winter season, but is still very sunny. October is a very pleasant time to travel to Tunisia. It is also a good month for excursions into the southern desert regions of Tunisia, which remain dry, but offer bearable daytime temperatures, although nights are exceedingly cold</p> */}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AboutSection;
