import React from  'react';

// components
import Navbar from '../../components/Navbar'
import PartenaireBackground from '../../components/Partenaire/PartenaireBackground';
import Partenaire from '../../components/Partenaire/index';
import FooterSection from '../../components/Footer'


const Homepage = () => {
    return(
       <div>
           <Navbar/>
           <PartenaireBackground bdtitle={'Organizers & Sponsors'} bdsub={'Partenaire'}/>
         

           <Partenaire/>

        
           <FooterSection/>
       </div>
    )
}

export default Homepage;