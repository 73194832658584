import React from 'react';
// import { Link } from 'react-router-dom'

const Conferance = () => {



    return (
        <div className="blog-area blog-grid section-padding">
            <div className="container">
                <div className="col-l2">
                    <div className="section-title-1 text-center">
                        <span>About Wegener</span>
                        <h2>About Wegener</h2>
                    </div>
                </div>


                <div className="organigation-text">
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>
                    The concept of WEGENER (Working Group of European Geoscientists for the Establishment of Networks for Earthquake Research) was put forward at a meeting of the Journees Luxembourgeoise de Geodynamique held in Walferdange in 1980 and the consortium was formally established during a meeting at IfAG (now BFG) in Frankfurt early in 1981. It was a voluntary collaboration among a large group of European institutions and individual scientists wishing to cooperate on the application of space and terrestrial geodetic techniques to the study of geodynamics in the Alpine-Mediterranean plate boundary region in the framework of NASA’s Crustal Dynamics Project (CDP).</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i> For now 40 years, WEGENER gathers geoscientists wishing to cooperate on the application of space and terrestrial geodetic techniques to the study of geodynamics in the Alpine–Mediterranean plate boundary region.</span>
                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>During the past decade, WEGENER has broadened its scopes beyond the Mediterranean and Geodesy. This led to the creation of a new Seismo-geodesy sub-commission, jointly supported by the International Association of Geodesy (IAG) and International Association of Seismology and Physics of the Earth’s Interior (IASPEI). </span>
                 {/* <p>After the opening ceremony and a plenary session (see Program), specific sessions will be dedicated to; new methodologies for geodetic observation and analysis, the integration of geodetic, tectonic and seismological data to better understand geophysics and geodynamics, earthquakes and the seismic cycle, potential fields, intracontinental deformation, etc. </p> */}
                </div>
            </div>
        </div >
    )

}

export default Conferance;
