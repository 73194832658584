import React from  'react';

// components
import Navbar from '../../components/Navbar'
import AttorneBreadcumbysDetails from '../../components/AttorneysDetails/cover'

import AttorneysDetails from '../../components/AttorneysDetails'
import FooterSection from '../../components/Footer'


const Attorneysdetails = () => {
    return(
       <div>
           <Navbar/>
           <AttorneBreadcumbysDetails bdtitle={'Progarms'} bdsub={'Progarms'}/>
           <AttorneysDetails/>
           <FooterSection/>
       </div>
    )
}

export default Attorneysdetails;