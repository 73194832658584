import React from 'react'
import './style.css'
import Accordion from 'react-bootstrap/Accordion'
import { Card, Button } from 'react-bootstrap'
import BlogSidebar from './Containe.accord'
import onm from '../../images/orghost/onm.png'
import wegener from '../../images/orghost/wegener.png'
import otc from '../../images/orghost/otc.png'
import inm from '../../images/orghost/inm.png'
import iag from '../../images/orghost/iag.png'
import iugg from '../../images/orghost/iugg.png'
import naget from '../../images/orghost/naget.png'
import etap from '../../images/orghost/etap.png'
// import Clarivate from '../../images/orghost/Clarivate.svg'
// import Elsevier from '../../images/orghost/Elsevier.svg'
// import Elsevierscopus from '../../images/orghost/Elsevier-Scopus.svg'

// import doxsotechnologies from '../../images/orghost/doxsotechnologies.png'
// import ierek from '../../images/orghost/ierek.png'
// import MaryMinnock from '../../images/orghost/MaryMinnock.png'
// import performer from '../../images/orghost/performer.png'


const FaqSection = () => {
    return (
        <section className="faq-area section-padding">
            <div className="container">
                <div>
                    <h2>Organizers & Sponsors</h2>
                </div>
                <div className="row">
                    <div className="col-lg-12">

                        <Accordion className="choose-info" defaultActiveKey="">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Organizers
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>

                                        <BlogSidebar list={[ onm]} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>



                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        Hosts
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>

                                        <BlogSidebar list={[wegener,onm]} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>


                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        Collaborators
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>

                                        <BlogSidebar list={[otc, inm]} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                        Sponsors
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>

                                        <BlogSidebar list={[ etap,iag, iugg]} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            {/* <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                        Publishers & Indexing Databases
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>

                                        <BlogSidebar list={[Clarivate, Elsevier, Elsevierscopus]} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                        Implementation & Management
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>

                                        <BlogSidebar list={[doxsotechnologies, ierek, performer]} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                        Website Editing
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="6">
                                    <Card.Body>

                                        <div className="post post2">
                                            <div className="img-holder">
                                                <img src={MaryMinnock} alt="" />
                                            </div>
                                            <div className="details">
                                                
                                                <h6>Mary Minnock</h6>

                                               <p>Mary Minnock Translation and Editing (see website)  </p> 
                                                <p> mary.minnock@yahoo.com </p>
                                                <p> + 353 87 1351297 </p>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}


                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default FaqSection;