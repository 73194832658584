import React from 'react'
import './styleguide.css'
import Accordion from 'react-bootstrap/Accordion'
import { Card, Button } from 'react-bootstrap'

const FaqSection = () => {
    return (
        <section className="faq-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <span> registration </span>
                            <h2> registration </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <Accordion className="choose-info" defaultActiveKey="">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Registration fees
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>

                                        <div className="organigation-text">
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>The conference registration fee covers round trip transport between airport tunis carthage-hotel in Sousse and all the conference materials and stay in a single room at a 5 star hotel in Sousse to include..</span>
                                            <p>* International participants can check in at the hotel the day before the conference (October 23, 2023) and check out one day after the conference (October 28, 2023) The cost of each extra night (150 EUR) will be added to the registration fee.
                                                * Accompanying person: 180 EUR per night in a double room (covering also the costs of 3 meals per day).</p>

                                            <i className="fa fa-check-square-o" aria-hidden="true">  Student:  400 EUR</i>
                                            <br></br>
                                            <i className="fa fa-check-square-o" aria-hidden="true"> Academic : 600 EUR</i>
                                            <br></br>
                                            <i className="fa fa-check-square-o" aria-hidden="true"> Professional : 700 EUR</i>
                                            <br></br>
                                            <i className="fa fa-check-square-o" aria-hidden="true"> Field trip  : 150 EUR</i>
                                            <br></br>

                                           <p>
                                           for Tunisian participants
                                            </p> 

                                            
                                            <i className="fa fa-check-square-o" aria-hidden="true">   Student: 1200 DT</i>
                                            <br></br>
                                            <i className="fa fa-check-square-o" aria-hidden="true">
                                            Professional : 1600 DT</i>
                                            <br></br>
                                            <i className="fa fa-check-square-o" aria-hidden="true">
                                              Academic: 1400 DT</i>
                                            <br></br>
                                            <i className="fa fa-check-square-o" aria-hidden="true">
                                            Field trip : 400 DT</i>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> • Accommodation (4 days, 3 nights – check-in on 24th of October, check-out on 27th of October 2023)</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> • 3 Breakfasts, 3 lunches and 3 dinners</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> • 5 Coffee breaks</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> • 1 Gala dinner</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> • Conference document</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> • Conference bag</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> • Certificate of participation</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> • link of Hotel congress <a href='https://www.sentidobellevue.com' target="_blank" >https://www.sentidobellevue.com</a> </span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i> •  Exhibition stand: 200 EUR (600 DT) for 1 m2 /day </span>


                                            {/* <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Accommodation (4 days, 3 nights – check-in on 24th of October, check-out on 27th of October 2023)</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>3 Breakfasts, 3 lunches and 3 dinners</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Ice breaker (event networking)</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Social program</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Coffee breaks</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>WEGENER notebook</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>WEGENER bag</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Springer certificate of participation</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Certificate of publication (Springer Nature)</span>
                                            <h1>tables</h1>
                                            * International participants may check into the hotel and register at the conference two days or more earlier (i.e. on or prior to the 22th or 23th October 2023) and check out two days or more after the conference (i.e. on or after the 28th or 29th of October 2023). The cost of each extra night (180 TND) will be added to the registration fee. Breakfast, lunch and dinner will be provided and included in the total registration fee during the extra days.
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Register to attend the conference with no accommodation</span>
                                            <p> There is a possibility to attend the conference with no accommodation. In this case, the registration fee covers:</p>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>2 lunches</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Coffee breaks</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Springer certificate of participation</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Conference bag</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Social program</span>
                                            <h1>tables</h1>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Register for virtual presentation</span>
                                            <p>
                                                If you cannot attend the conference but still wish to have your paper presented and considered for publication in the special issues, we offer a virtual presentation option for your convenience. Virtual presenters are expected to record a voice-over PowerPoint (more details will be sent after online registration) and submit the file online (link will be sent after payment of the registration fee) before 10 September 2023. Virtual presenters are still required to submit an abstract and, if they wish, an extended abstract before the deadlines (see Important Dates), and at least one author must pay the registration fee. Your conference documents will be sent to your email after the conference.
                                            </p>
                                            <h1>tables</h1>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Register to include your accepted paper in the proceedings without attending the conference</span>
                                            <p>
                                                If your paper is accepted, it may be published in the conference proceedings by Springer even if you are not attending the conference. In this case, a fee of 238 TND for each accepted paper must be paid before the deadline of 10 September 2023. The fee covers the costs of the editorial handling and peer-review evaluation of your paper through the conference evaluation system. The accepted paper will not be included in the final conference program (see Author Guidelines and Publications for more details).                                             </p>
                                            <h1>tables</h1>
                                            <span>More information</span>



                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>To be included in the final conference program, at least one author of each accepted abstract must register online to attend the conference and select the payment method before the deadline of 10 September 2023 (see "Payment terms" below for more details).</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>If multiple short papers by the same first author are accepted in the final conference proceedings and program, an amount of 238 TND should be paid for each additional paper.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>It is possible to attend the conference without submitting a paper after registering online and paying the registration fee online or onsite.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Publishing the extended abstract in the (indexed) proceedings does not require an in-person or a virtual presentation of the accepted (short) abstract.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>It is possible to attend the conference (in-person or online) without submitting an abstract after registering online and paying the registration fee onsite or online (preferred) by 10 September 2023 .</span>
 */}

                                        </div>
                                    </Card.Body>





                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        Payment can be made via the following methods:                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="organigation-text">


                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>    • By Bank Transfer (the full amount without any deduction should be paid including the bank transfer fees).</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>    • By Order Form / Bank Check (only for Tunisia-based participants).</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>    •  Cash payments at arrival are allowed conditionally upon an email request that has to be sent.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>    • Please send the scanned copies (confirmation of payment or order form/bank check for locals) by email to: wegener.assembly@onm.nat.tn or in registration forms.</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>    • Deadline for online registration for the conference trips and hotel is 31 August 2023.</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>    • Registration will open on 28 May 2023</span>




                                        </div>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        Payments (by Bank Transfer or Online Gateway) should be made to the bank details below:                                   </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <div className="organigation-text">



                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   • National Office of Mines of Tunisia</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   • Tax Registration Number (Matricule Fiscale): 001130PAM000.</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   • Address: Office National des Mines de Tunisie, 24 Rue de l’énergie-2035, Charguia I.B.P. 215, Tunis, Tunisie.</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   • Bank name: Banque Internationale Arabe de Tunisie - BIAT</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   • Account name: Office National de Mines</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   • Account number (RIB): 08 002000 6430000169 12</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   • IBAN: TN59 0800 2000 6430 0001 6912</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   • BIC: BIATTNTT</span>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>   • Reason for transaction: 20th WEGENER Assembly 2023</span>


                                        </div>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            {/* <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                        Registration form                                     </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <div className="organigation-text">
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Registration will open on 05 July 2023 (see Important Dates). </span>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}
                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default FaqSection;