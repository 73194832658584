import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import ContactForm2 from '../CommentForm2'
import './style.css'
import cd_5 from '../../images/studies/1.jpg';
import cd_6 from '../../images/studies/2.jpg';
import cd_7 from '../../images/studies/3.jpg';


class AttorneysDetails extends Component {

    render() {

        return (
            <div className="Attorneys-content-section section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">


                            <div className="education-section">
                                <div className="section-title">
                                    <h2>Programs</h2>
                                </div>
                               <p>The final and detailed version of the program will be announced on 10 October 2023.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="Attorneys-area">
                                <div className="container att-s">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="Attorneys-info">
                                                <div className="Attorneys-content">
                                                    <h3> 23 Oct. </h3>
                                                </div>
                                                <div className="info-outer">
                                                    <ul className="info">
                                                        <li><span>8:30--19:00 </span> <span> </span>Training day in Seismology / Geodesy </li>
                                                        <li><h5>Room2</h5>  </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="Attorneys-area">
                                <div className="container att-s">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="Attorneys-info">
                                                <div className="Attorneys-content">
                                                    <h3> 24 Oct. </h3>
                                                </div>
                                                <div className="info-outer">
                                                    <ul className="info">
                                                        <li><span>8:00--11:00 </span> <span> </span>Arrival of participants / Registration </li>
                                                        <li><span>11:00--12:30 </span> <span> </span>20th WEGENER Opening Words </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>12:30--14:00 </span> <span> </span> Lunch </li>
                                                        <li><span>14:00--16:00 </span> <span> </span> Session 1 </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>16:00 - 16:30 </span> <span> </span> Coffee Break </li>
                                                        <li><span>16:30 - 17:30 </span> <span> </span> Session 2 </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>19:00 </span> <span> </span> Dinner </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="Attorneys-area">
                                <div className="container att-s">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="Attorneys-info">
                                                <div className="Attorneys-content">
                                                    <h3> 25 Oct. </h3>
                                                </div>
                                                <div className="info-outer">
                                                    <ul className="info">
                                                        <li><span>8:30--10:30 </span> <span> </span>Session 3 </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>8:30--10:30 </span> <span> </span>Session 4 </li>
                                                        <li><h5>Room2</h5>  </li>
                                                        <li><span>10:30 - 11:00 </span> <span> </span> Coffee Break </li>

                                                        <li><span>11:00--12:30 </span> <span> </span>Session 3 </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>11:00--12:30 </span> <span> </span>Session 4 </li>
                                                        <li><h5>Room2</h5>  </li>

                                                        <li><span>12:30--14:00 </span> <span> </span> Lunch </li>
                                                        <li><span>14:00--16:00 </span> <span> </span> Session 5 </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>14:00--16:00 </span> <span> </span> Session 6 </li>
                                                        <li><h5>Room2</h5>  </li>
                                                        <li><span>16:00 - 16:30 </span> <span> </span> Coffee Break </li>
                                                        <li><span>16:30 - 17:30 </span> <span> </span> Session 5 </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>16:30 - 17:30 </span> <span> </span> Session 6 </li>
                                                        <li><h5>Room2</h5>  </li>

                                                        <li><span>19:00 </span> <span> </span> Dinner </li>

                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="Attorneys-area">
                                <div className="container att-s">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="Attorneys-info">
                                                <div className="Attorneys-content">
                                                    <h3> 26 Oct.  </h3>
                                                </div>
                                                <div className="info-outer">
                                                    <ul className="info">
                                                        <li><span>8:30--10:30 </span> <span> </span>Session 7 </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>8:30--10:30 </span> <span> </span>Session 8 </li>
                                                        <li><h5>Room2</h5>  </li>
                                                        <li><span>10:30 - 11:00 </span> <span> </span> Coffee Break </li>

                                                        <li><span>11:00--12:30 </span> <span> </span>Session 7 </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>11:00--12:30 </span> <span> </span>Session 8 </li>
                                                        <li><h5>Room2</h5>  </li>
                                                        <li><span>12:30--14:00 </span> <span> </span> Lunch </li>
                                                        <li><span>14:00--16:00 </span> <span> </span> Session 9 </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>14:00--16:00 </span> <span> </span> Session 9 </li>
                                                        <li><h5>Room2</h5>  </li>
                                                        <li><span>16:00 - 16:30 </span> <span> </span> Coffee Break </li>
                                                        <li><span>16:30 - 17:30 </span> <span> </span> Poster session  </li>
                                                        <li><h5>Room2</h5>  </li>
                                                        <li><span>16:30 - 17:30 </span> <span> </span> Wegener Board meeting </li>
                                                        <li><h5>Room1</h5>  </li>
                                                        <li><span>19:00 </span> <span> </span> Dinner gala </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="Attorneys-area">
                                <div className="container att-s">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="Attorneys-info">
                                                <div className="Attorneys-content">
                                                    <h3> 27 Oct.  </h3>
                                                </div>
                                                <div className="info-outer">
                                                    <ul className="info">
                                                        <li><span> Field excursion </span> <span> </span> </li>
                                                     
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default AttorneysDetails;