import React from 'react';
// import { Link } from 'react-router-dom'
import abimg1 from '../../images/about/medgu2022.jpg'
// import abimg2 from '../../images/about/img-1.png'
import Mediterranean from '../../images/about/Mediterranean.png'

import './style.css'

const AboutSection = () => {

    return (
        <div className="about-style-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-m">

                        <div className="section-title">
                            <h2>Sessions</h2>
                            <p>

                                Penaries are planned on themes related to the tracks below. More details will be made available soon:
                            </p>
                        </div>

                        <div className="about-content-1">
                            <div className="section-title">


                                <li>
                                    <h5>
                                    Session 1. The earthquake sequence of 6 February 2023: Surface ruptures and geodynamic model at a triple junction.
                                        </h5>
                                    <br></br>
                                </li>
                                <li>
                                    <h5>
                                    Session 2. From Hazard to Risk: Implications of geodetic research to society.                                </h5>

                                    <br></br>
                                </li>
                                <li>
                                    <h5>Session 3. Improving understanding of magmatic and volcanic process.


                                    </h5>
                                    <br></br>
                                </li>
                                <li>
                                    <h5>
                                    Session 4. The seismic cycle: from transient and precursory deformation to seismic ruptures.
                                    </h5>
                                    <br></br>
                                </li>
                                <li>
                                    <h5>
                                    Session 5. Active faults: reconciling short- and long-term observations.                                        </h5>
                                    <br></br>
                                </li>
                                <li>
                                    <h5>
                                    Session 6. Intracontinental deformation and slowly deforming areas.
                                        
                                        </h5>
                                    <br></br>
                                </li>
                                <li>
                                    <h5>
                                    Session 7. Vertical movements of the earth surface, sea level and potential fields.                                        </h5>
                                    <br></br>
                                </li>
                                <li>
                                    <h5>
                                    Session 8. Multi-timescale glacier and landslides processes.

                                    </h5>
                                    <br></br>
                                </li>
                                <li>
                                    <h5>

                                    Session 9. Technical and methodological developments, and large networks handling.
                                    </h5>
                                    <br></br>
                                </li>
                                <li>
                                    <h5>
                                    Session 10. Geodesy: general contributions, networks development and status.

                                    </h5>
                                    <br></br>
                                </li>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}

export default AboutSection;
