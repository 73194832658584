import React from 'react';
// import { Link } from 'react-router-dom'
import abimg1 from '../../images/about/medgu2022.jpg'
// import abimg2 from '../../images/about/img-1.png'
import Mediterranean from '../../images/about/Mediterranean.png'

import './style.css'

const AboutSection = () => {

    return (
        <div className="about-style-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-m">

                        {/* <h2>Workshops </h2>


                        More details will be made available soon. */}

                        <h2>Trainings </h2>

                        <p>More details will be made available soon.</p>
                        Training day in Seismology / Geodesy



                    </div>

                </div>
            </div>
        </div>
    )

}

export default AboutSection;
