import React from  'react';

// components
import Navbar from '../../components/Navbar'
import SpeakersBackground from '../../components/workspaces/SpeakersBackground';
import Speakers from '../../components/workspaces/index';
import FooterSection from '../../components/Footer'


const Homepage = () => {
    return(
       <div>
           <Navbar/>
           <SpeakersBackground bdtitle={'Trainings '} bdsub={'Partenaire'}/>
           <Speakers/>
           <FooterSection/>
       </div>
    )
}

export default Homepage;