import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/Register/index'

import Formaulaire from '../../components/formulaire'
import FooterSection from '../../components/Footer'


const FAQPageForms = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={' Register '} bdsub={' Register '}/>
           <Formaulaire/>
           <FooterSection/>
       </div>
    )
}

export default FAQPageForms;