import React, { Component } from 'react';
// import { Link } from 'react-router-dom'

import pr_1 from '../../images/practice/jornal-asti.png';


class SectionProceedings extends Component {

    render() {

        return (
            <div style={{backgroundColor:"#8bbcfc"}}  className="practice-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-12 p-p organigation-text">
                            <div className="field-img">
                                <img src={pr_1} alt="" />
                            </div>

                        </div>
                        <div className="col-lg-8 col-12">
                            <div className="organigation-area">

                                <div className="organigation-text">
                                    <h2>About Wegener</h2>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>The concept of WEGENER (Working Group of European Geoscientists for the Establishment of Networks for Earthquake Research) was put forward at a meeting of the Journees Luxembourgeoise de Geodynamique held in Walferdange in 1980 and the consortium was formally established during a meeting at IfAG (now BFG) in Frankfurt early in 1981.</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>It was a voluntary collaboration among a large group of European institutions and individual scientists wishing to cooperate on the application of space and terrestrial geodetic techniques to the study of geodynamics in the Alpine-Mediterranean plate boundary region in the framework of NASA’s Crustal Dynamics Project (CDP).</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>For now 40 years, WEGENER gathers geoscientists wishing to cooperate on the application of space and terrestrial geodetic techniques to the study of geodynamics in the Alpine–Mediterranean plate boundary region.</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>During the past decade, WEGENER has broadened its scopes beyond the Mediterranean and Geodesy. This led to the creation of a new Seismo-geodesy sub-commission, jointly supported by the International Association of Geodesy (IAG) and International Association of Seismology and Physics of the Earth’s Interior (IASPEI). </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default SectionProceedings;