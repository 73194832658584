import React from 'react'
import './styleguide.css'
import Accordion from 'react-bootstrap/Accordion'
import { Card, Button } from 'react-bootstrap'
import {Link} from 'react-router-dom'

const FaqSection = () => {
    return (
        <section className="faq-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <span> Submission </span>
                            {/* <h2> Author Guidelines </h2> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <Accordion className="choose-info" defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Submission
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>

                                        <div className="organigation-text">


                                             <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  • Abstracts (max. 350 words written in English) should be covered by one or more of the Conference Sessions. </span>
                                          <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  • Figures and tables are not acceptable in the abstract </span>
                                          <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  • Authors of accepted abstracts will present their studies orally (a PPT presentation of 15 min). </span>
                                                                        
                                          <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  • To submit your abstract, please use this email address wegener.assembly@onm.nat.tn or  <Link className="comment-reply-link" to="/forms/registration/form">in registration forms</Link> . </span>
                                          <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  • Following numerous requests, we inform you that the deadline for submitting abstracts has been extended until: 31 july 2023. </span>
                                          <span><i className="fa fa-check-square-o" aria-hidden="true"></i>  • At least one author of each accepted abstract must register before the registration deadline (15 August 2023) to warrant inclusion in the final conference program (for more details, see Registration). </span>

                                        </div>
                                    </Card.Body>





                                </Accordion.Collapse>
                            </Card>
                            {/* <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        How to prepare your abstract (max. 350 words)
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                  

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        How to prepare your short conference paper (i.e. extended abstract)
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <div className="organigation-text">


                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Short papers (min. 3 pages / max. 5 pages) should be covered by one or more of Conference Sessions.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Please note the strict deadline for submissions of short conference papers (15 September 2023).</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>The paper should be written in English and prepared using the WEGENER Short Paper Template.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Figures and tables should be embedded and not supplied separately.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>All papers will undergo a peer-review process by track chairs and members of the Scientific Committee.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>For notification of acceptance, please see Important Dates.</span>


                                        </div>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                        How to submit your abstract and your short conference paper
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>

                                        <div className="organigation-text">

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Firstly, a short abstract (max. 350 words) should be submitted online (Performer submission system) to assess the quality of your work using the WEGENER Abstract Template.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>If accepted, you will be invited to extend your abstract, but this is optional and it is not a prerequisite for presenting your work (summarized in the abstract) at WEGENER-20.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>To submit your abstract, please click here on “Submit” or on the “Submit” button on Home.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>After registration on the Performer system, you will receive an email with a link to validate your registration and create your account.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>You can then submit your abstract as follows:</span>
                                            <div style={{ marginLeft: "10%" }} >

                                                <li>Insert the title of your study in the "Title" section.</li>
                                                <li>Insert the full abstract in the "Abstract" section (max. 350 words).</li>
                                                <li>Select the major topic of your study (see Conference Sessions).</li>
                                                <li>Upload your Abstract (Word file).</li>
                                                <li>Press the "Submit" button at the end of the form.</li>

                                            </div>

                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>After acceptance of your abstract, you will be invited to submit (optional) an extended version of your abstract using the Template.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>If you accept our invitation, follow the same procedure to submit your short proceedings paper (extended abstract) by uploading the Word file.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>If you decide not to accept our invitation, click on “decline to publish an extended abstract in the proceedings” to exclude yourself from receiving further details relating to the submission and publication of an extended abstract.</span>
                                            <span><i className="fa fa-check-square-o" aria-hidden="true"></i>After acceptance of your short paper, the corresponding author should sign the Springer Consent-to-Publish form to authorize publication in the conference proceedings (see Important Dates).</span>
                                        </div>


                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}
                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default FaqSection;