import React from 'react';
import sousseT from '../../images/blog/sousseTHotel.jpg'
import './style.css'

const Contactpage = () => {

    return (
        <div className="contact-page-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            {/* <span>  Venue  </span> */}
                            <h2>  Venue  </h2>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col col-xs-12">
                        <div className="contact-map">
                            <iframe title='db' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57763.58882182253!2d55.38442113562169!3d25.195692423227655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2z4Kam4KeB4Kas4Ka-4KaHIC0g4Kam4KeB4Kas4Ka-4KaHIOCmhuCmruCmv-CmsOCmvuCmpCAtIOCmuOCmguCmr-CngeCmleCnjeCmpCDgpobgprDgpqwg4KaG4Kau4Ka_4Kaw4Ka-4Kak!5e0!3m2!1sbn!2sbd!4v1540725271741"></iframe>
                        </div>
                    </div>
                </div> */}
                <div className="row">

                    <div className="col-lg-13 col-md-12">
                        <div className="organigation-text">



                                                       {/* <span><i className="fa fa-check-square-o" aria-hidden="true"></i> Sousse or Soussa (Berber origin) is a city located in northeastern Tunisia. It is the capital of the Sousse Governorate. Located 140 km south of the capital Tunis on the Gulf of Hammamet, the city has 280 000 inhabitants. The present city has grown to include the ruins of Hadrumetum, which had many names in several languages during antiquity. Its economy is based on transport equipment, processed food, olive oil, textiles and tourism.</span>
                                                       <span><i className="fa fa-check-square-o" aria-hidden="true"></i> The city is host to many international conferences and other important regional and international events. We have chosen Sousse to host the WEGENER-20 for its exoticism and modernity. The facilities for hosting conferences in the city have evolved, especially in terms of hotels and conference centers.</span>
                                                       <span><i className="fa fa-check-square-o" aria-hidden="true"></i> When you arrive at Tunis Carthage Airport, your driver will greet you with a WEGENER-20 plate and take you to the conference hotel in Sousse… Avoid the stress of long shuttle queues and make the most of your journey from the moment you arrive at Tunis Carthage Airport. The driver knows the area well and will make sure that you have a smooth ride.</span>
                                                       */}
                                                       <span><i className="fa fa-check-square-o" aria-hidden="true"></i>                                                        The 20th WEGENER Assembly 2023 will be held from 24 to 27 October 2023 at the Sentido Bellevue Park located directly on the beautiful, long and quiet sandy beach of El Kantaoui, Sousse, NE Tunisia.      .</span>
                    
                                                       <span><i className="fa fa-check-square-o" aria-hidden="true"></i> • link of Hotel congress <a href='https://www.sentidobellevue.com' target="_blank" >https://www.sentidobellevue.com</a> </span>

                     
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="practice-section-img practice-section-img-2">
                            <img style={{ width: "100%" }} src={sousseT} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Contactpage;
