import React from 'react';

// components
import Navbar from '../../components/Navbar'
import FooterSection from '../../components/Footer'
import TripImages from '../../components/TripAbout/TripImages'
import TripAbouts from '../../components/TripAbout/index'
const TripAbout = () => {
    return (
        <div>
            <Navbar />
            <TripImages />
            <TripAbouts />
            <FooterSection />
        </div>
    )
}

export default TripAbout;