import React, { Component } from 'react';
// import { Link } from 'react-router-dom'
import './style.css'
import cd_1 from '../../images/case-studies-details/Musicology.jpg';
import cd_21 from '../../images/case-studies-details/carthage.jpg';
import cd_22 from '../../images/case-studies-details/medina-sousse.jpg';
import cd_23 from '../../images/case-studies-details/sidi-boussaid.jpg';
import cd_24 from '../../images/case-studies-details/DiscoverPort.jpg';
import cd_25 from '../../images/case-studies-details/DiscoverPort2.jpg';



class CaseDetails extends Component {

    sumitHandler(e) {
        e.preventDefault()
    }

    render() {

        return (
            <div className='pr-sub'>
                <div className="practice-details-area case-stadies-details-area section-padding">

                    <div className="container">
                        <div className="col-l2">
                            <div className="section-title section-title2">
                                <h2>North African / Mediterranean Musicology</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="practice-section-text practice-section-text-2">
                                    <p>Following the award ceremony and the Gala dinner on Thursday evening, October 26th and to celebrate the closure of the 20th WEGENER Assembly 2023, we are delighted to announce that we have lined up a particularly brilliant and varied evening of North African / Mediterranean music for you, performed by gifted local musicians… Wishing you lots of fun! </p>
                                </div>
                                <div className="practice-section-img practice-section-img-2">
                                    <img style={{ width: "100%" }} src={cd_1} alt="" />
                                </div>
                            </div>
                            <br />
                            <div className="col-lg-8">

                                <div className="col-l2">
                                    <div className="section-title section-title2">
                                        <h2>Guided Tours </h2>
                                    </div>
                                </div>
                                <div className="practice-section-text practice-section-text-2">



                                    <p>We are also planning a guided tour of old city Sousse and a visit to the Mediterranean village Sidi Bou Said and the ancient site of Carthage on Thursday, October 26th after lunch at 2 pm.</p>

                                    <p>The two hour tour of old city Sousse includes many of the major sites of the medina (old town) of Sousse, one of the finest examples of Arab architecture in Tunisia, preserved almost completely unchanged throughout the centuries.</p>

                                    <p>The half day visit to the Mediterranean village Sidi Bou Said and the ancient site of Carthage will continue to amaze you throughout your entire trip. The entire village town is located on top of a steep cliff north of Tunis, which overlooks the Mediterranean Sea. The view from Sidi Bou Said is absolutely phenomenal. The entire village is simply beautiful, with flowers draped from almost every wall and doorway, and beautiful flowing vines hanging throughout the entire area.</p>

                                    <p>The ancient site of Carthage was located on the edge of North Africa that is now Tunisia, on the Eastern side of Lake Tunis. Carthage, from this prime location, could control trade from the eastern to the Western Mediterranean. Carthage was founded as a Phoenician Colony about 800 BC. It was once the center of the Phoenicians’ vast trade empire. Carthage was later conquered and destroyed by the Romans in 146 BC. It was rebuilt by the Romans and occupied by early Arab conquerors.</p>

                                    <p>Admission and participation in the guided tours are free of charge.</p>


                                </div>

                            </div>
                            <div className="col-lg-4 p-p">
                                <div className="row">

                                    <div className="col-lg-12 col-md-6">
                                        <div className="practice-catagory-item">
                                            <div className="practice-section-img practice-section-img-2">
                                                <img src={cd_22} alt="" />
                                            </div>
                                        </div>
                                        <div className="practice-catagory-item">

                                            <div className="practice-section-img practice-section-img-2">
                                                <img src={cd_21} alt="" />
                                            </div>
                                        </div>
                                        <div className="practice-catagory-item">

                                            <div className="practice-section-img practice-section-img-2">
                                                <img src={cd_23} alt="" />
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="studies-area studies-area-3 section-padding">
                    <div className="container">
                        <div className="col-l2">
                            <div className="section-title section-title2">
                                <h2>Evening Walks: </h2>
                            </div>
                        </div>
                        <div className="row">
                            <p>
                                You can enjoy the nearby Gold Club or take evening walks along the Mediterranean coastline and discover Port El Kantaoui.
                            </p>
                            <div className="col-lg-8 col-md-6 col-sm-6">
                                <div className="practice-section-text practice-section-text-2">
                                     <p>Port El Kantaoui has one of the best-known marinas in the Mediterranean. A charming pleasure port, with its typical Moorish architecture and hustle and bustle along its quays, it is an attractive touristic pole where good living and relaxation are the predominant features. It is located in the center of the coastal touristic area of Sousse with its numerous hotel and structures dedicated to the entertainment and leisure activities of holidaymakers.</p>

                                     <p>At a distance of 12 km from this marina is the town of Sousse, a famous seaside resort of the Tunisian Sahel. It was founded during the Phoenician era and was known as “Hadrumetum”. It became an important town of the Roman province and then of the Aghlabid dynasty. This port town, today known as the “Pearl of the Sahel” invites you to visit its museums and various historical sites. Its medina and typical alleys display its ancestral architecture, colors and local handicrafts and have been listed as a UNESCO World Heritage Site.</p>

                                     <p>The whole Sahel region, between the littoral and inland, has yielded a large portion of Tunisian heritage treasures throughout its history, with remarkable archeological sites such as Lamta or El Djem and its famous coliseum which hosts an international festival of classical music in summer.</p>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="studies-item">
                                    <div className="studies-single">
                                        <img src={cd_25} alt="" />
                                    </div>

                                </div>
                                <div className="studies-item">
                                    <div className="studies-single">
                                        <img src={cd_24} alt="" />
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default CaseDetails;