import React from  'react';

// components
import Navbar from '../../components/Navbar'

import FooterSection from '../../components/Footer'
import WlecomeImages from '../../components/AccessPlan/welcomeImages'
import AccessPlan from '../../components/AccessPlan/index'


const WelcomePage = () => {
    return(
       <div className="practicepage">
           <Navbar/>
           <WlecomeImages bdtitle={'Access Plan'} bdsub={'Access Plan '}/>
           <AccessPlan/>
           <FooterSection/>
       </div>
    )
}

export default WelcomePage;