import React from 'react';

// components
import Navbar from '../../components/Navbar'
import SpeakersBackground from '../../components/Speakers/SpeakersBackground';

import SimpleSlider from '../../components/hero'
import AboutSection from '../../components/about'
import FooterSection from '../../components/Footer'
import SectionProceedings from '../../components/SectionProceedings'
import AccordHome from '../../components/AccordHome'
import HomeKeys from '../../components/HomeKeys'
import WlecomeImages from '../../components/Welcome/welcomeImages'
import Wlecome from '../../components/Welcome/index'

const Homepage = () => {
    return (
        <div>
            <Navbar />
            {/* <SpeakersBackground bdtitle={'About wegener-20'} bdsub={'Partenaire'} /> */}
            {/* <SimpleSlider /> */}
            <WlecomeImages bdtitle={''} bdsub={''} />
            <Wlecome />
            <AboutSection />
            {/* <SectionProceedings /> */}
            {/* <AccordHome /> */}
            {/* <HomeKeys /> */}
            <FooterSection />
        </div>
    )
}

export default Homepage;