import React, { Component } from 'react';
import './style.css'
import cd_1 from '../../images/Attorneys-single/RoundTableDiscussion.jpg';
import welcomet1 from '../../images/welcomepage/photo 1.jpg'
import welcomet123 from '../../images/newimages/hotel-txt2.png'

class AttorneysDetails extends Component {

    render() {

        return (
            <div className="Attorneys-content-section section-padding">
                <div className="container" >
                <div className="row">
                <div className="signature about-img">
                                <img style={{height:"700px"}} src={welcomet123} alt="" />
                            </div>
                </div>

                    <div className="row">
                    <div className="col-lg-6">
                    <div className="signature about-img">
                                <img style={{height:"700px"}} src={welcomet1} alt="" />
                            </div>
                       
                       </div>
                        <div className="col-lg-6">

                            <div className="exrienense-section">
                                {/* <h2>Welcome</h2> */}
                                <p>Dear geoscientists, dear colleagues,</p>

                                <p>We are pleased to announce that the 20th WEGENER Assembly 2023 (WEGENER-20) will be organized by the National Office of Mines (ONM, Geological Survey of Tunisia) from 24 to 27 October 2023 in Sousse, Tunisia.</p>

                                <p>The ONM has shown a substantial interest in the processing of GNSS data and the geodynamic study of Tunisia since 2019.</p>

                                <p>The 20th Assembly of Wegener will be held in collaboration with the National Institute of Meteorology (INM, in charge of Seismology) and the Office of Topography and Cadastre (OTC, in charge of Geodesy), with support from the International Union of Geodesy and Geophysics (IUGG), the International Association of Geodesy (IAG), the Centre National d'Etudes Spatiales (CNES), The Géoazur laboratory, the International Association of Seismology (IASPEI) and the North African Group for Earthquake and Tsunami studies (NAGET).</p>

                                <p>This assembly aims to gather geo-scientists and share recent progress in space geodesy (GPS and InSAR) and knowledge in seismo-geodesy, deformation of volcanic fields. A special attention will be devoted to the implications for the seismic hazard and risk reduction to foster discussion and new proposals for joint research projects.</p>

                                <p>WEGENER-20 is looking forward to cordially welcoming you in Sousse... Your participation in-person or virtually will support WEGENER’s mission of ensuring a sustainable future for humanity in the region and for the planet.</p>

                                <p>Join us for a memorable experience!</p>


                            </div>
                            <div className="language">
                                <p>Yours sincerely,</p>
                            </div>
                           
                            {/* <div className="exrienense-img">
                                <img src={cd_1} alt="" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AttorneysDetails;