import React, { useState } from 'react';

import './style.css'
import axios from 'axios';
import Logintets from './serve'
function BlogSidebar() {

    const [value, setvalue] = useState({})
    const [file, setfile] = useState(null)
    const [filename, setfilename] = useState("")


    const onchange = (e) => {
        console.log(e.target.value)
        console.log(e.target.name)
        setvalue({ ...value, [e.target.name]: e.target.value })
    }
    const SubmitHandler = (e) => {
        // e.preventDefault()
        console.log(e)
        console.log(value)
        var formData = new FormData();
        formData.append(
            "filet",
            file,
        );
        formData.append(
            "firstname",
            value.firstname,

        );
        formData.append(
            "lastname",
            value.lastname,

        );
        formData.append(
            "phone_number",
            value.phone_number,

        );
        formData.append(
            "email",
            value.email,

        );
        formData.append(
            "address",
            value.address,

        );
        formData.append(
            "abstract",
            value.abstract,

        );
        formData.append(
            "file",
            filename,

        );
        
        axios.post("https://back.congress-onm.tn/api/admins/user/add", formData).then((response) => {
            console.log(response.data)
            setvalue({})
            // window.location.href = window.location.href
            alert('submission completed successfully')
        }).catch((e) => {
            alert("chose your data")
        })
    }
    const onRecordChange = (event) => {
        console.log(event.target.files)
        setfile(event.target.files[0]);
        setfilename(event.target.files[0].name);
    };

    return (
        <div className=" container blog-right-bar practice-details-area case-stadies-details-area">
            <div className="row container">
                <div style={{ backgroundColor: "#cec8d1", padding: "11%", margin: "20px", marginRight: "30px" }} className="col-lg-12 col-12">
                    <p>Following numerous requests, we inform you that the deadline for submitting abstracts has been extended until: 31 july 2023.”</p>
                    <div className="widget ">
                <Logintets />

                        {/* <form >
                            <div>
                                <input name="firstname" onChange={(e) => onchange(e)} type="text" style={{ padding: "20px", marginTop: "10px" }} className="form-control" placeholder="Fisrt Name" />
                                <input name="lastname" onChange={(e) => onchange(e)} type="text" style={{ padding: "20px", marginTop: "10px" }} className="form-control" placeholder="Last Name" />
                                <input name="phone_number" onChange={(e) => onchange(e)} type="text" style={{ padding: "20px", marginTop: "10px" }} className="form-control" placeholder="Phone number" />
                                <input name="email" onChange={(e) => onchange(e)} type="text" style={{ padding: "20px", marginTop: "10px" }} className="form-control" placeholder="email" />
                                <input name="country" onChange={(e) => onchange(e)} type="text" style={{ padding: "20px", marginTop: "10px" }} className="form-control" placeholder="country" />
                                <input name="address" onChange={(e) => onchange(e)} type="text" style={{ padding: "20px", marginTop: "10px" }} className="form-control" placeholder="address" />
                                <textarea type="text" name="abstract" onChange={(e) => onchange(e)} rows="10" cols="30" style={{ padding: "20px", marginTop: "10px" }} className="form-control" placeholder="Abstract" />
                                <input type="file" onChange={(e) => onRecordChange(e)} style={{ padding: "20px", marginTop: "10px" }} className="form-control" placeholder="file" />
                            </div>
                        </form>
                        <button style={{ width: "100%", backgroundColor: "#22cbf0", padding: "20px", marginTop: "10px" }} onClick={() => SubmitHandler()} >Submit</button> */}

                    </div>
                </div>
            </div>
        </div>
    )

}

export default BlogSidebar;
