import React from  'react';

// components
import Navbar from '../../components/Navbar'

import FooterSection from '../../components/Footer'
import WlecomeImages from '../../components/Contact/welcomeImages'
import Accomodation from '../../components/Contact/index'


const WelcomePage = () => {
    return(
       <div className="practicepage">
           <Navbar/>
           <WlecomeImages bdtitle={'Contact'} bdsub={'Contact'}/>
           <Accomodation/>
           <FooterSection/>
       </div>
    )
}

export default WelcomePage;