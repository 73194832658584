import React from 'react';
import { Link } from 'react-router-dom'
import blog1 from '../../images/blog/1.jpg'

import faouzidhaha from '../../images/commitess/faouzi-dhaha.png'
import Bahrouni from '../../images/commitess/Bahrouni-Nejib.png'
import Nabil from '../../images/commitess/nabil-khelifi.png'

///////////////////////////////
import Mustapha_Meghraoui from '../../images/commitess/Mustapha_Meghraoui.png'
import Nocquet from '../../images/commitess/Jean-Mathieu-NOCQUET.png'
import Ridha from '../../images/commitess/Ridha-Maamri.png'
import Mohamed from '../../images/commitess/Mohamed-Arfaou.png'
import Walid from '../../images/commitess/Walid-Guiras.png'
import Marzouguiwissem from '../../images/commitess/Wissem-Marzougui.png'
import Chouaieb from '../../images/commitess/Haifa-Chouaieb.png'
import Ksentini from '../../images/commitess/Ahmed-Ksentini.png'
import HassenHamdi from '../../images/commitess/Hassen-HAMDI.png'
import Nouali from '../../images/commitess/Kamel-NOUALI.png'

import MoradAmmar from '../../images/commitess/Mourad-Amer.png'
import Sahbimolla from '../../images/commitess/sahbi-moalla.png'
const Commities = () => {
    const list1 = [
        {
            name: 'Faouzi Dhaha',
            role: 'President',
            prof: 'ONM, Tunisia',
            image: faouzidhaha
        },
        {
            name: 'Néjib Bahrouni',
            role: 'Vice-President',
            prof: 'ONM, Tunisia',
            image: Bahrouni
        },

    ]
    const list2 = [
        {
            name: 'Mustapha Meghraoui',
            prof: 'IPG Strasbourg, France ',
            image: Mustapha_Meghraoui
        },
        {
            name: 'Jean-Mathieu Nocquet',
            prof: 'IRD, France',
            image: Nocquet
        },
        {
            name: 'Ridha Maamri',
            prof: 'ONM, Tunisia',
            image: Ridha
        },
        {
            name: 'Mohamed Arfaoui',
            prof: 'ONM, Tunisia',
            image: Mohamed
        },
        {
            name: 'Walid Guiras',
            prof: 'ONM, Tunisia',
            image: Walid
        },
        {
            name: 'Wissem Marzougui',
            prof: 'ONM, Tunisia',
            image: Marzouguiwissem
        },
        {
            name: 'Haifa Chouaieb',
            prof: 'ONM, Tunisia',
            image: Chouaieb
        },
        {
            name: 'Ahmed Ksentini ',
            prof: 'FSS, Tunisia',
            image: Ksentini
        },
        {
            name: 'Kamel Nouali ',
            prof: 'OTC, Tunisia ',
            image: Nouali
        },
        {
            name: 'Hassen Hamdi',
            prof: 'INM, Tunisia ',
            image: HassenHamdi
        },
        {
            name: 'Mohamed Sahbi Moalla',
            role: 'Manager',
            prof: 'Performer – The Leading Conference Organiser, Tunisia ISET, University of Sfax, Tunisia ',
            image: Sahbimolla
        },
        {
            name: 'Mourad Amer',
            role: 'Co-Manager ',
            prof: 'Founder & CEO of IEREK Editor of ASTI Series (Springer/IEREK) IEREK, Alexandria, Egypt',
            image: MoradAmmar
        }


    ]

    const list3 = [
       { name: ' Mustapha MEGHRAOUI',
        role: '  (ITES, France)',},
      {  name: ' Jean - Mathieu NOCQUET',
        role: '  (IRD, France)',},
      {  name: ' Ridha MAAMRI',
        role: '  (ONM, Tunisia)',},
       { name: '  Mohamed ARFAOUI',
        role: '   (ONM, Tunisia)',},
      {  name: '  Walid GUIRAS',
        role: '   (ONM, Tunisia)',},
       { name: '   Wissem MARZOUGUI',
        role: '   (ONM, Tunisia)',},
      {  name: '  Haifa CHOUAIEB',
        role: '   (ONM, Tunisia)',},
      {  name: '  Ahmed KSENTINI',
        role: '   (FSS, Tunisia)',},
      {  name: '  Kamel NOUALI',
        role: '   (OTC, Tunisia)',},
      {  name: '  Hassen HAMDI',
        role: '  (INM, Tunisia).',}

    ]



    return (
        <div className="blog-area blog-grid section-padding">
            <div className="container">
                <div className="col-l2">
                    <div className="section-title-1 text-center">
                        <span>Organizing Committee</span>
                        <h2> Organizing Committee</h2>
                    </div>
                </div>
                <div className="row">
                    {list1.map((e) => (
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="blog-item">

                                <div className="blog-content">
                                    {/* <div className="blog-img">
                                        <img src={e.image} alt="" />
                                    </div> */}
                                    <h3><Link >{e.name}</Link></h3>
                                    <ul className="post-meta">

                                        <li><Link >{e.role}</Link></li>
                                        <li> {e.prof}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                {/* <div className="col-l2">
                    <div className="section-title-1 text-center">
                        <span>Organizing Committee</span>
                        <h2>Members</h2>
                    </div>
                </div> */}
                <div className="col-l2">
                    <div className="section-title-1 text-center">
                        <span>Organizing Committee</span>
                        <h2> Members</h2>
                    </div>
                </div>
                <div className="row">
                    {list3.map((e) => (
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="blog-item">

                                <div className="blog-content">
                                    {/* <div className="blog-img">
                                        <img src={e.image} alt="" />
                                    </div> */}
                                    <h3><Link >{e.name}</Link></h3>
                                    <ul className="post-meta">

                                        <li> {e.role}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                {/* <div className="col-l2">
                    <div className="section-title-1 text-center">
                        <span>Organizing Committee</span>
                        <h2>Management</h2>
                    </div>
                </div> */}
                <div className="row">
                    {[].map((e) => (
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="blog-item">

                                <div className="blog-content">
                                    {/* <div className="blog-img">
                                        <img src={e.image} alt="" />
                                    </div> */}
                                    <h3><Link >{e.name}</Link></h3>
                                    <ul className="post-meta">

                                        <li> {e.prof}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

            </div>
        </div >
    )

}

export default Commities;
