import React from 'react';

import {Link} from 'react-router-dom'

import './style.css'

const Breadcumb = (props) => {

    return(
        <div className="breadcumb-areaGuide">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcumb-wrap text-center">
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default Breadcumb;
