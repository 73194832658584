import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios'
import {
    Box,
    Button,
    Container,
    Grid,
    FormControlLabel,
    Checkbox,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
// import Page from 'src/components/Page';
// import { useForm } from "react-hook-form";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
// import { Eye as eyepassword} from 'react-feather'
// const eye = <FontAwesomeIcon icon={faEye} />;

// var urlpostuser = require('../../const/userurl')() +"/api/users/signin"

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const LoginView = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const [checkedTO,setcheckedTO] = useState(true)
    const classes = useStyles();
    const [file, setfile] = useState(null)
    const [filename, setfilename] = useState("")
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const onRecordChange = (event) => {
        console.log(event.target.files)
        setfile(event.target.files[0]);
        setfilename(event.target.files[0].name);
    };
    const SubmitHandler = (value) => {
        // e.preventDefault()
        console.log(value)
        var formData = new FormData();
        formData.append(
            "filet",
            file,
        );
        formData.append(
            "firstname",
            value.firstname,

        );
        formData.append(
            "lastname",
            value.lastname,

        );
        formData.append(
            "phone_number",
            value.phonenumber,

        );
        formData.append(
            "country",
            value.country,

        );
        formData.append(
            "email",
            value.email,

        );
        formData.append(
            "address",
            value.address,

        );
        formData.append(
            "abstract",
            value.abstract,

        );
        formData.append(
            "file",
            filename,

        );
        axios.defaults.baseURL = 'https://back.congress-onm.tn';
        axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
                axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.post("https://back.congress-onm.tn/api/admins/user/add", formData).then((response) => {
            console.log(response.data)
            // setvalue({})
            // window.location.href = window.location.href
            alert('submission completed successfully')
        }).catch((e) => {
            alert("chose your data")
        })
    }

    return (
        <div
            style={{ backgroundColor: "#cec8d1" }}
        >
            <div className="row">
                <FormControlLabel
                    label="Author"
                    control={<Checkbox checked={checkedTO} onChange={(e) => setcheckedTO(e.target.checked)} />}
                />
                <FormControlLabel
                    label="Participant"
                    control={<Checkbox checked={!checkedTO} onChange={(e) => setcheckedTO(!(e.target.checked))} />}
                />

            </div>
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="lg">
                    <Formik
                        initialValues={{
                            email: '',
                            firstname: '',
                            lastname: '',
                            phonenumber: '',
                            country: '',
                            abstract: ''
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            firstname: Yup.string().max(255).required('firstname is required'),
                            lastname: Yup.string().max(255).required('lastname is required'),
                            phonenumber: Yup.string().max(255).required('phonenumber is required'),
                            country: Yup.string().max(255).required('country is required'),
                            abstract: Yup.string().max(350),

                        })}


                        onSubmit={(values, { setSubmitting }) => {
                            //   navigate('/app/adddl', { replace: true });
                            // navigate('/app/dashboard', { replace: true });

                            console.log(values)
                            SubmitHandler(values)


                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>


                                </Box>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    {/* <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <Button

                        fullWidth
                        startIcon={<FacebookIcon />}
                        onClick={handleSubmit}
                        size="large"
                        variant="contained"
                      >
                        Login with Facebook
                    </Button>
                    </Grid> */}
                                    {/*  <Grid
                      item
                      xs={12}
                      md={6}
                    >
                      <Button
                        fullWidth
                        startIcon={<GoogleIcon />}
                        onClick={handleSubmit}
                        size="large"
                        variant="contained"
                      >
                        Login with Google
                    </Button>
                    </Grid> */}
                                </Grid>
                                <Box
                                    mt={3}
                                    mb={1}
                                >
                                    <Typography
                                        align="center"
                                        color="textSecondary"
                                        variant="body1"
                                    >
                                        {/*  or login with email address */}
                                    </Typography>
                                </Box>
                                <TextField
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    error={Boolean(touched.firstname && errors.firstname)}
                                    fullWidth
                                    helperText={touched.firstname && errors.firstname}
                                    label="first Name"
                                    margin="normal"
                                    name="firstname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.firstname}
                                    variant="outlined"
                                />
                                <TextField
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    error={Boolean(touched.lastname && errors.lastname)}
                                    fullWidth
                                    helperText={touched.lastname && errors.lastname}
                                    label="last Name"
                                    margin="normal"
                                    name="lastname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.lastname}
                                    variant="outlined"
                                />
                                <TextField
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    error={Boolean(touched.phonenumber && errors.phonenumber)}
                                    fullWidth
                                    helperText={touched.phonenumber && errors.phonenumber}
                                    label="phone number"
                                    margin="normal"
                                    name="phonenumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.phonenumber}
                                    variant="outlined"
                                />
                                <TextField
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="email"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.email}
                                    variant="outlined"
                                />
                                <TextField
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    error={Boolean(touched.country && errors.country)}
                                    fullWidth
                                    helperText={touched.country && errors.country}
                                    label="country"
                                    margin="normal"
                                    name="country"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.country}
                                    variant="outlined"
                                />
                                <TextField
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    error={Boolean(touched.address && errors.address)}
                                    fullWidth
                                    helperText={touched.address && errors.address}
                                    label="address"
                                    margin="normal"
                                    name="address"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.address}
                                    variant="outlined"
                                />
                            {checkedTO &&    <TextField
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    error={Boolean(touched.abstract && errors.abstract)}
                                    fullWidth
                                    helperText={touched.abstract && errors.abstract}
                                    label="abstract"
                                    margin="normal"
                                    name="abstract"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.abstract}
                                    variant="outlined"
                                    multiline
                                />}
                              {checkedTO &&  <input type="file" onChange={(e) => onRecordChange(e)} style={{ padding: "20px", marginTop: "10px" }} className="form-control" placeholder="file" />
}
                                <Box my={2}>
                                    <Button
                                        style={{ background: "#1E90FF" }}


                                        // disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Sign in now
                                    </Button>
                                </Box>
                                {/* <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    Don&apos;t have an account?
                                    {' '}
                                    <Link
                                        component={RouterLink}
                                        to="/register"
                                        variant="h6"
                                    >
                                        Sign up
                                    </Link>
                                </Typography> */}
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </div>
    );
};

export default LoginView;
