import React from 'react';
import ContactForm from '../CommentForm'
import './style.css'



const ContactSection = () => {

    return(
        <div style={{ margin:"20px"}} className="contact-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contact-text">
                            <h2>Are You Interest To Contact With Us?</h2>
                            {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC.</p> */}
                            <div className="contact-sub">
                                <div className="contact-icon">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                                <div className="contact-c">
                                    <h4>Our Location</h4>
                                    <span>Siège social de l'ONM 24,rue de L'Energie, 2035 La Charguia - Tunis</span>
                                </div>
                            </div>
                            <div className="contact-sub">
                                <div className="contact-icon">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                </div>
                                <div className="contact-c">
                                    <h4>Phone</h4>
                                    <span>(+216) 71 808 042 / 71 808 266 / 93 027 098</span>
                                </div>
                            </div>
                            <div className="contact-sub">
                                <div className="contact-icon">
                                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                </div>
                                <div className="contact-c">
                                    <h4>Email</h4>
                                    <span>wegener.assembly@onm.nat.tn</span>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
     )
        
}

export default ContactSection;
