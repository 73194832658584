import React from 'react';
import { Link } from 'react-router-dom'
import './styleAccord.css'

function BlogSidebar(props) {

   

    return (
        <div className="blog-right-bar practice-details-area case-stadies-details-area">
            <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                    <div className="instagram">
                        <ul className="d-flex">

                            {props.list.map(e => (

                                <li><Link to="/blog-single"><img src={e} alt="" /></Link></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default BlogSidebar;
