import React, { Component } from 'react';
import './style.css'
import cd_1 from '../../images/Attorneys-single/RoundTableDiscussion.jpg';

class AttorneysDetails extends Component {

    render() {

        return (
            <div className="Attorneys-content-section section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">

                            <div className="exrienense-section">
                                <h2>Call for Papers</h2>
                                <p>Feel free to share the official conference Call for Papers with your colleagues. </p>

                               

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AttorneysDetails;