import React, { Component } from 'react';
import './style.css'
import cd_1 from '../../images/Attorneys-single/RoundTableDiscussion.jpg';

class AttorneysDetails extends Component {

    render() {

        return (
            <div className="Attorneys-content-section section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="col-l2">
                                <div className="section-title section-title2">
                                    <h2> Conference Hotel </h2>
                                </div>
                            </div>
                            <div className="exrienense-section">

                                <p>

                                    The full conference will be held in a hotel (five stars) in Sousse. More details will be announced soon.
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AttorneysDetails;